import {
  Box,
  BoxProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  SlideFade,
  Text,
  chakra,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';

import Link from 'next/link';
import PersistantModal from './atomics/PersistantModal';
import ReservationRouteContext from '@/contexts/ReservationRoute';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import WrapIf from './atomics/WrapIf';
// import useMewsButton from '@/hooks/useMews';

// import dynamic from 'next/dynamic';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { ContactDiadao } from './ContactDiadao';
import { RestaurantForm } from './restaurant/RestaurantForm';
import BookingDrawer from './BookingDrawer';
//import PlanitySoins from '../pages/iframe/PlanitySoins';
// const DynamicPxier = dynamic(() => import('./Pxier'), {
//   ssr: false,
// });

interface ReservationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReservationDrawer = (props: ReservationDrawerProps) => {
  const { isOpen: _isOpen, onClose: _onClose } = props;

  const {
    route,
    setRoute: _setRoute,
    modalRoute,
    setModalRoute,
  } = useContext(ReservationRouteContext);

  const settingsCtx = useContext(SettingsContext);

  const { currentLang } = useContext(LanguageContext);

  const boatUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetBoatUrl']?.value;

  const deckchairUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetDeckchairUrl']?.value;

  const eventsUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetEventsUrl']?.value;

  const giftUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetGiftUrl']?.value;

  const restaurantLittorineUrl =
    settingsCtx?.settings?.modules?.bookingRestaurantWidgetUrl;
  const restaurantCasotUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetRestaurantCasotUrl']
      ?.value;
  const restaurantPlageUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetRestaurantPlageUrl']
      ?.value;
  const restaurantBonCadeauxUrl =
    settingsCtx?.settings?.modules?.extraFields?.[
      'widgetRestaurantBonCadeauxUrl'
    ]?.value;

    const careUrl =
    settingsCtx?.settings?.modules?.extraFields?.['care']
      ?.value;

  const [isOpen, setIsOpen] = useState(_isOpen);

  // const buttonProps = useMewsButton();

  const stay = useKustomTranslation('resa_stay')?.value?.[currentLang];
  const table = useKustomTranslation('resa_table')?.value?.[currentLang];
  const care = useKustomTranslation('resa_care')?.value?.[currentLang];
  const deckchair =
    useKustomTranslation('resa_deckchair')?.value?.[currentLang];
  const boat = useKustomTranslation('resa_boat')?.value?.[currentLang];
  const event = useKustomTranslation('resa_event')?.value?.[currentLang];
  const gift_box = useKustomTranslation('resa_gift_box')?.value?.[currentLang];
  const gift_card =
    useKustomTranslation('resa_gift_card')?.value?.[currentLang];
  const littorine =
    useKustomTranslation('resa_littorine')?.value?.[currentLang];
  const casot = useKustomTranslation('resa_casot')?.value?.[currentLang];



  const items = [
    // {
    //   icon: 'icon-elmes-bed-grey',
    //   // label: 'Séjour',
    //   label: stay,
    //   isButton: true,
    //   href: '#reservation',
    //   ...buttonProps,
    // },
    {
      icon: 'icon-elmes-bed-grey',
      // label: 'Séjour',
      label: stay,
      sidebarModalHref: '/reservation',
      isExternalLink: false,
    },
    {
      icon: 'icon-elmes-service-grey',
      // label: 'Table',
      label: table,
      sidebarHref: '/restaurant',
      isExternalLink: false,
    },
    {
      icon: 'icon-elmes-spa2-grey',
      // label: 'Soin',
      label: care,
      // sidebarModalHref: '/spa',
      // isExternalLink: false,
      sidebarModalHref: '/care',
      isExternalLink: false,
    },
    {
      icon: 'icon-elmes-deckchair-grey',
      // label: 'Transats',
      label: deckchair,
      sidebarModalHref: '/deckchair',
      isExternalLink: false,
    },
    {
      icon: 'icon-elmes-boat-grey',
      // label: 'Bateau',
      label: boat,
      href: boatUrl || '#',
      isExternalLink: true,
    },
    {
      icon: 'icon-elmes-chears-grey',
      // label: 'Événement',
      label: event,
      sidebarModalHref: '/events',
      isExternalLink: false,
    },
    {
      icon: 'icon-elmes-gift2-grey',
      // label: 'Coffret cadeau',
      label: gift_box,
      href: giftUrl,
      isExternalLink: true,
    },
  ];

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  const setRoute = (value: string, isModal?: boolean) => {
    if (isModal) {
      setModalRoute(value);
    } else {
      _setRoute(value);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setRoute('');
    _onClose();
  };

  useEffect(() => {
    if (isOpen && !route) {
      setRoute('/');
    }
    document.body.style.overflow = isOpen || modalRoute ? 'hidden' : 'auto';
  }, [isOpen, modalRoute]);

  useEffect(() => {
    if (route) {
      setIsOpen(true);
    }
  }, [route]);

  const [isModalLoading, setIsModalLoading] = useState(false);
  const modalRoutes = [
    '/restaurant-littorine',
    '/restaurant-casot',
    '/restaurant-plage',
    '/restaurant-bon-cadeaux',
    '/deckchair',
    '/events',
    '/care',
  ];
  useEffect(() => {
    // set route require loading
    if (modalRoutes.includes(modalRoute)) {
      setIsModalLoading(true);
    }
  }, [modalRoute]);

  const openDrawerFromHSP = (e: Event) => {
    if (!isOpen && (e as CustomEvent).detail === 'prix') setIsOpen(true);
  };

  useEffect(() => {
    window.addEventListener('diadaoBooking', (e) => openDrawerFromHSP(e));
    return () => {
      window.removeEventListener('diadaoBooking', (e) => openDrawerFromHSP(e));
    };
  }, []);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        blockScrollOnMount={false}
        trapFocus={false}
      >
        <DrawerOverlay onClick={() => {}} />
        <DrawerContent maxWidth={['100%', null, '360px']}>
          <Box
            bgColor="white"
            boxShadow={isOpen ? '-29px 0px 93px #00000029' : 'none'}
            minH="100vh"
            display="flex"
          >
            {route !== '/' && (
              <IconButton
                aria-label="Back"
                fontSize={['14px']}
                p={[5, null, null, 0]}
                py={[6, null, null, 0]}
                bgColor="transparent"
                _hover={{
                  bgColor: 'gray.100',
                }}
                icon={
                  <chakra.span
                    position="relative"
                    left="-3px"
                    className="icon-diadao-arrow-left"
                  />
                }
                position="absolute"
                top={1}
                left={1}
                onClick={() => setRoute('/')}
              />
            )}
            <IconButton
              aria-label="Close"
              fontSize={['12px']}
              p={[5, null, null, 0]}
              py={[6, null, null, 0]}
              bgColor="transparent"
              _hover={{
                bgColor: 'gray.100',
              }}
              icon={
                <chakra.span className="icon-diadao-cross1" fontSize="14px" />
              }
              position="absolute"
              top={1}
              right={1}
              onClick={onClose}
            />
            {
              {
                '/': (
                  <MenuLayout>
                    <SlideFade offsetX="30px" offsetY={0} in key="1">
                      <Flex flexDir="column" py={8}>
                        {items.map((item, index) => (
                          <MenuItem
                            key={index}
                            {...item}
                            setRoute={setRoute}
                            index={index}
                          />
                        ))}
                      </Flex>
                    </SlideFade>
                  </MenuLayout>
                ),
                '/restaurant': (
                  <MenuLayout>
                    <SlideFade offsetX="30px" in key="2" offsetY={0}>
                      <Flex flexDir="column" py={8}>
                        <MenuItem
                          icon="icon-elmes-service-grey"
                          // label="Table"
                          label={table}
                        />
                        <MenuItem
                          // label="La Littorine"
                          label={littorine}
                          sidebarModalHref="/restaurant-littorine"
                          setRoute={setRoute}
                        />
                        <MenuItem
                          // label="Le Casot"
                          label={casot}
                          index={1}
                          sidebarModalHref="/restaurant-casot"
                          setRoute={setRoute}
                        />
                        <MenuItem
                          // label="Bon cadeaux"
                          label={gift_card}
                          index={1}
                          sidebarModalHref="/restaurant-bon-cadeaux"
                          setRoute={setRoute}
                        />
                      </Flex>
                    </SlideFade>
                  </MenuLayout>
                ),
              }[route]
            }
          </Box>
        </DrawerContent>
      </Drawer>
      <BookingDrawer
        isOpen={modalRoute === '/reservation'}
        // isOpen={true}
        onClose={() => setModalRoute('')}
      />
      {modalRoutes.includes(modalRoute) && (
        <PersistantModal
          isOpen={!!modalRoute}
          isLoading={
            modalRoute === '/restaurant-casot' ? false : isModalLoading
          }
          onClose={() => setModalRoute('')}
          modalStyle={{
            maxW: {
              // '/spa': ['100%', null, '1500px'],
              '/events': ['100%', null, '90%'],
              '/restaurant-littorine': ['100%', null, '600px'],
              '/restaurant-casot': ['100%', null, '600px'],
              '/restaurant-plage': ['100%', null, '600px'],
              '/restaurant-bon-cadeaux': ['100%', null, '600px'],
              '/deckchair': ['100%', null, '1000px'],
              '/care': ['100%', null, '1000px']
            }[modalRoute],
            maxH: {
              '/restaurant-littorine': ['100%', null, '630px'],
              '/restaurant-casot': ['100%', null, '630px'],
              '/restaurant-plage': ['100%', null, '630px'],
              '/restaurant-bon-cadeaux': ['100%', null, '680px'],
              '/deckchair': ['100%', null, '760px'],
            }[modalRoute],
            my: [0, null, 16],
            pt: 14,
          }}
        >
          {modalRoute === '/restaurant-littorine' && (
            <chakra.iframe
              src={restaurantLittorineUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {modalRoute === '/restaurant-casot' && (
            <RestaurantForm
              onClose={() => {
                setModalRoute('');
                onClose();
              }}
            />
          )}
          {modalRoute === '/restaurant-bon-cadeaux' && (
            <chakra.iframe
              src={restaurantBonCadeauxUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {modalRoute === '/restaurant-plage' && (
            <chakra.iframe
              src={restaurantPlageUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {modalRoute === '/deckchair' && (
            <chakra.iframe
              src={deckchairUrl}
              width="100%"
              height="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            ></chakra.iframe>
          )}
          {modalRoute === '/events' && (
            <chakra.iframe
              key="events-iframe"
              w="100%"
              h="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              src={eventsUrl}
              onLoad={() => {
                setIsModalLoading(false);
              }}
            />
          )}
          {modalRoute === '/care' && (
            <chakra.iframe
              src={'/iframe/planity.html'}
              key="events-iframe"
              w="100%"
              h="100%"
              opacity={isModalLoading ? 0 : 1}
              transition="opacity 0.3s"
              onLoad={() => {
                setIsModalLoading(false);
              }}
            />
          )}
        </PersistantModal>
      )}
    </>
  );
};

const MenuLayout = ({ children }: BoxProps) => {
  const { settings } = useContext(SettingsContext);

  const { currentLang } = useContext(LanguageContext);
  const title = useKustomTranslation('resa_title')?.value?.[currentLang];

  const phoneNumber = settings?.informations?.phone;
  const mail = settings?.informations?.mainEmail;
  return (
    <Box px={5} pt={16} pb={10} display="flex" flexDir="column" flexGrow={1}>
      <Heading
        as="h2"
        fontSize={['24px']}
        color="black"
        fontFamily="samsungSharpSans"
        fontWeight="bold"
      >
        {title}
      </Heading>
      <Box flexGrow={1}>{children}</Box>
      <ContactDiadao />
    </Box>
  );
};

interface MenuItemProps {
  icon?: string;
  label?: string;
  href?: string;
  onClick?: () => void;
  className?: string;
  sidebarHref?: string;
  sidebarModalHref?: string;
  isExternalLink?: boolean;
  setRoute?: (route: string, isModal?: boolean) => void;
  index?: number;
  isButton?: boolean;
}

const MenuItem = ({
  icon,
  label = '',
  href,
  onClick,
  className,
  setRoute,
  sidebarHref,
  sidebarModalHref,
  isExternalLink = false,
  isButton = false,
  index,
}: MenuItemProps) => {
  const isInteractible = !!href || !!sidebarHref || !!sidebarModalHref;
  return (
    <WrapIf
      condition={!!href}
      key={label}
      wrapper={(c) => (
        <Link href={href!} target={isExternalLink ? '_blank' : undefined}>
          {c}
        </Link>
      )}
    >
      <Box
        key={label}
        display="flex"
        alignItems="center"
        className={className}
        py={[icon ? 4 : 7, null, null, icon ? 2 : 4]}
        px={2}
        color="gray.800"
        cursor={isInteractible ? 'pointer' : 'default'}
        borderTop={!index ? 'none' : '1px solid'}
        borderColor="gray.100"
        borderRadius="md"
        _hover={
          isInteractible
            ? {
                bgColor: '#f8f8f8',
              }
            : {}
        }
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            if (sidebarHref) {
              setRoute?.(sidebarHref, false);
            }
            if (sidebarModalHref) {
              setRoute?.(sidebarModalHref, true);
            }
          }
        }}
        position="relative"
        role="group"
      >
        {icon && (
          <chakra.span
            position="relative"
            mr="5"
            fontSize="36px"
            className={icon}
          ></chakra.span>
        )}
        <Text
          fontFamily="samsungSharpSans"
          fontWeight="bold"
          fontSize={['14px']}
          color="gray.800"
          as="div"
        >
          {label}
        </Text>
        {isInteractible && (
          <chakra.span
            position="absolute"
            right={[0, null, null, isExternalLink ? 2.5 : 2]}
            className={
              isExternalLink
                ? 'icon-elmes-ext-link-grey'
                : 'icon-elmes-arrow-right'
            }
            fontSize={isExternalLink ? '38px' : '42px'}
            transition="transform 0.2s ease"
            _groupHover={{
              transform: 'translate(7px, 0)',
            }}
          />
        )}
      </Box>
    </WrapIf>
  );
};

export default ReservationDrawer;
